:root {
  --primary-color: #fff200;
  --secondary-color: #282c34;
  --bg-color: #f5f6fa;
}
* {
  box-sizing: border-box;
}
#root {
  overflow: hidden;
}

html {
  background-color: var(--bg-color);
}
.border-rounded {
  border-radius: 10px;
}
.uk-button {
  text-transform: unset !important;
}
.uk-button.pagination {
  padding: 0 7px;
  border-radius: 4px;
}
.uk-input.pagination,
.uk-select.pagination {
  width: 100px;
}
.overflow-visible {
  overflow: unset;
}
