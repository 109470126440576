.sidebarWrapper {
  max-width: 300px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  transition: max-width 0.2s ease-in-out;
}
.sidebarWrapper.hidden {
  max-width: 0;
}
.sidebar {
  width: 300px;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  position: relative;
}
.main {
  width:100%;
  /* calc(100% - 300px); */
  position: relative;
  left: 300px;
  transition: all 0.2s ease-in-out;
  padding-bottom: 50px;
  overflow: hidden;
}
.main.full {
  width: 100%;
  left: 0;
}
.sidebar .logo > img {
  height: 40px;
}
.sidebar .navLink {
  padding: 0 20px;
  border-radius: 10px;
  color: #ccc;
  vertical-align: middle;
  display: flex;
  height: 65px;
  align-items: center;
}
.sidebar .navLink:hover,
.sidebar .navLink.active {
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}
.sidebar .navIcon {
  font-size: 1.5rem;
  opacity: 0.5;
  margin-right: 15px;
}

/* HEADER ***************************** */
.menu {
  font-size: 1.5rem;
  cursor: pointer;
}

.fullLoader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
