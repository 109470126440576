.loginWrapper {
  min-height: 100vh;
  padding: 15px;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  height: 40px;
}
