.body {
  width: auto;
  min-width: 200px;
  max-width: 95vw;
  position: relative;
  display: flow-root;
  margin-top: auto !important;
  margin-bottom: auto !important;
  animation: openme 0.05s forwards linear;
  outline: 0 !important;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  position: fixed;
  inset: 0px;
  padding: 50px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
}
@keyframes openme {
  0% {
    opacity: 0.6;
    /* transform: translate(-50%, -50%) scale(1.1); */
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    /* transform: translate(-50%, -50%) scale(1); */
    transform: scale(1);
  }
}
